import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { CheckCircle, AlertCircle, Calendar, X, ArrowRight, Clock, ToggleLeft, ToggleRight } from 'lucide-react';
import { BillingFrequency, SubscriptionState, SubscriptionStatus } from "../../../store/subscriptionSlice";
import { formatDate } from "../../services/util";

interface SubscriptionInfoCardProps {
    title: string;
    value: string | React.ReactNode;
    icon: React.ReactNode;
    pendingValue?: string;
}

const SubscriptionInfoCard: React.FC<SubscriptionInfoCardProps> = ({ title, value, icon, pendingValue }) => (
    <div className="bg-gray-50 p-6 rounded-lg shadow-sm transition-all duration-300 hover:shadow-md">
        <div className="flex items-center mb-3">
            <div className="p-2 bg-teal-100 rounded-full mr-3">
                {React.cloneElement(icon as React.ReactElement, { className: "w-6 h-6 text-teal-600" })}
            </div>
            <h4 className="text-lg font-semibold text-gray-700">{title}</h4>
        </div>
        <div className="text-xl font-bold text-gray-900 mb-2">{value}</div>
        {pendingValue && (
            <div className="flex items-center mt-2 text-blue-600">
                <ArrowRight className="w-4 h-4 mr-1" />
                <p className="text-sm font-semibold">Changing to: {pendingValue}</p>
            </div>
        )}
    </div>
);

const getLocalizedString = (key: string): string => {
    const translations: { [key: string]: string } = {
        'status.trialing': 'Trial',
        'status.active': 'Active',
        'status.past_due': 'Past Due',
        'status.canceled': 'Canceled',
        'billing.monthly': 'Monthly',
        'billing.yearly': 'Yearly',
    };
    return translations[key] || key;
};

const getStatusDisplay = (status: SubscriptionStatus): string => {
    const statusMap: Record<SubscriptionStatus, string> = {
        [SubscriptionStatus.Trialing]: 'status.trialing',
        [SubscriptionStatus.Active]: 'status.active',
        [SubscriptionStatus.PastDue]: 'status.past_due',
        [SubscriptionStatus.Canceled]: 'status.canceled',
        [SubscriptionStatus.Invalid]: 'status.invalid',
    };
    return getLocalizedString(statusMap[status] || status);
};

const getBillingFrequencyDisplay = (frequency: BillingFrequency): string => {
    const frequencyMap: Record<BillingFrequency, string> = {
        [BillingFrequency.Monthly]: 'billing.monthly',
        [BillingFrequency.Yearly]: 'billing.yearly',
    };
    return getLocalizedString(frequencyMap[frequency] || frequency);
};

export const CurrentSubscription: React.FC = () => {
    const {
        currentPlan,
        subscriptionStatus,
        billingFrequency,
        endsAt,
        cancelledAt,
        pendingPlan,
        pendingBillingFrequency,
        autoRenewal
    } = useSelector<RootState, SubscriptionState>((state) => state.subscription);

    return (
        <div className="bg-white p-8 rounded-xl shadow-md">
            <h3 className="text-2xl font-bold text-gray-800 mb-6">Current Subscription Details</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                <SubscriptionInfoCard
                    title="Current Plan"
                    value={currentPlan || 'N/A'}
                    icon={<CheckCircle />}
                    pendingValue={pendingPlan || undefined}
                />
                <SubscriptionInfoCard
                    title="Status"
                    value={getStatusDisplay(subscriptionStatus as SubscriptionStatus)}
                    icon={<AlertCircle />}
                />
                <SubscriptionInfoCard
                    title="Billing Frequency"
                    value={getBillingFrequencyDisplay(billingFrequency as BillingFrequency)}
                    icon={<Calendar />}
                    pendingValue={pendingBillingFrequency ? getBillingFrequencyDisplay(pendingBillingFrequency as BillingFrequency) : undefined}
                />
                <SubscriptionInfoCard
                    title="Current Period Ends"
                    value={formatDate(endsAt)}
                    icon={<Clock />}
                />
                {cancelledAt && (
                    <SubscriptionInfoCard
                        title="Cancelled At"
                        value={formatDate(cancelledAt)}
                        icon={<X />}
                    />
                )}
                <SubscriptionInfoCard
                    title="Auto Renewal"
                    value={
                        <div className="flex items-center">
                            {autoRenewal ? (
                                <>
                                    <ToggleRight className="w-6 h-6 text-green-500 mr-2" />
                                    <span className="text-green-500">Enabled</span>
                                </>
                            ) : (
                                <>
                                    <ToggleLeft className="w-6 h-6 text-red-500 mr-2" />
                                    <span className="text-red-500">Disabled</span>
                                </>
                            )}
                        </div>
                    }
                    icon={autoRenewal ? <ToggleRight /> : <ToggleLeft />}
                />
            </div>
            {(pendingPlan || pendingBillingFrequency) && (
                <div className="mt-8 p-6 bg-blue-50 rounded-lg border border-blue-200">
                    <h4 className="text-xl font-semibold text-blue-700 mb-3">Pending Changes</h4>
                    <p className="text-blue-600">
                        Your subscription will change at the end of the current billing period.
                        {pendingPlan && ` The new plan will be: ${pendingPlan}.`}
                        {pendingBillingFrequency && ` The new billing frequency will be: ${getBillingFrequencyDisplay(pendingBillingFrequency as BillingFrequency)}.`}
                    </p>
                </div>
            )}
        </div>
    );
};