import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Car, Link as LinkIcon, Trash2, AlertCircle } from 'lucide-react';
import { RootState } from "../../store";
import { removeAccount } from "../../store/teslaAccountsSlice";
import { randomStr } from "../services/util";
import { getTokenData } from "../../store/epics/userEpics";
import Button from '../common/Button';

const STATE_LENGTH = 20;

const LinkedAccounts: React.FC = () => {
    const dispatch = useDispatch();
    const teslaAccountsState = useSelector((state: RootState) => state.teslaAccounts);
    const accounts = teslaAccountsState?.accounts || [];

    useEffect(() => {
        // If you need to fetch accounts from an API, you can do it here
    }, []);

    const handleAddAccount = (e: React.FormEvent) => {
        e.preventDefault();

        const tokenData = getTokenData();
        if (tokenData == null) {
            alert('You have to sign in to complete this request');
            return;
        }

        const clientId = import.meta.env.VITE_TESLA_CLIENT_ID;
        const redirectUri = encodeURIComponent(`https://${import.meta.env.VITE_DOMAIN}/tesla/callback?access_token=${tokenData.access_token}`);
        const scope = encodeURIComponent('openid user_data email offline_access vehicle_device_data vehicle_charging_cmds');
        const state = randomStr(STATE_LENGTH);

        window.location.href = `https://auth.tesla.com/oauth2/v3/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=${scope}&state=${state}`;
    };

    const handleRemoveAccount = (id: number) => {
        dispatch(removeAccount(id));
    };

    if (teslaAccountsState.loading) {
        return <div className="flex justify-center items-center h-64">
            <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-teal-500"></div>
        </div>;
    }

    return (
        <div>
            {teslaAccountsState.error && (
                <div className="mb-6 p-4 bg-red-100 border-l-4 border-red-500 text-red-700 rounded">
                    <div className="flex items-center">
                        <AlertCircle className="mr-2" />
                        <p>Error: {teslaAccountsState.error}</p>
                    </div>
                </div>
            )}

            {accounts.length === 0 ? (
                <p className="text-gray-600 mb-6">No Tesla accounts linked yet.</p>
            ) : (
                <ul className="mb-6 space-y-4">
                    {accounts.map((account) => (
                        <li key={account.id} className="bg-gray-50 rounded-lg p-4 transition-all duration-300 hover:shadow-md">
                            <div className="flex items-center justify-between mb-3">
                                <span className="font-semibold text-lg text-gray-800">{account.email}</span>
                                <button
                                    onClick={() => handleRemoveAccount(account.id)}
                                    className="text-red-500 hover:text-red-700 transition-colors duration-300"
                                    aria-label="Remove account"
                                >
                                    <Trash2 size={20} />
                                </button>
                            </div>
                            <ul className="space-y-2">
                                {account.vehicles.map((vehicle) => (
                                    <li key={vehicle.id} className="flex items-center text-gray-600">
                                        <Car className="mr-2 text-teal-500" size={18} />
                                        <span>{vehicle.name}</span>
                                    </li>
                                ))}
                            </ul>
                        </li>
                    ))}
                </ul>
            )}

            <form onSubmit={handleAddAccount} className="mt-8">
                <Button type="submit" fullWidth>
                    <LinkIcon size={20} className="mr-2" />
                    <span>Link Tesla Account</span>
                </Button>
            </form>
        </div>
    );
};

export default LinkedAccounts;