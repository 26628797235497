import React from 'react';
import EVAlarmLayout from "../layout/EVAlarmLayout";
import { Link } from 'react-router-dom';

const TermsOfService = () => {
    return (
        <EVAlarmLayout>
            <div className="max-w-3xl mx-auto py-12 px-4">
                <h1 className="text-3xl font-bold mb-6">EVAlarm Terms of Service</h1>
                <p className="text-sm text-gray-600 mb-8 italic">Last updated: October 10, 2024. Please note that this is a revised version and may be subject to further updates.</p>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">1. Acceptance of Terms</h2>
                    <p>By accessing or using the EVAlarm application and services, you agree to be bound by these Terms of Service. If you do not agree to these terms, please do not use our services.</p>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">2. Description of Service</h2>
                    <p>EVAlarm provides a mobile application designed to alert Tesla owners about their charging status and help prevent overstays at charging stations.</p>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">3. User Responsibilities</h2>
                    <p>Users are responsible for maintaining the confidentiality of their account information and for all activities that occur under their account. Users agree to use the service in compliance with all applicable laws and regulations.</p>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">4. Privacy Policy</h2>
                    <p>Your use of EVAlarm is also governed by our Privacy Policy, which can be found <Link to="/privacy" className="text-teal-600 hover:underline">here</Link>.</p>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">5. Limitations of Liability</h2>
                    <p>To the fullest extent permitted by applicable law, EVAlarm and its affiliates, officers, employees, agents, partners, and licensors shall not be liable for any indirect, incidental, special, consequential, punitive, or exemplary damages, including but not limited to damages for loss of profits, goodwill, use, data, or other intangible losses (even if EVAlarm has been advised of the possibility of such damages), resulting from:</p>
                    <ul className="list-disc pl-8 mt-2">
                        <li>Your use or inability to use the service</li>
                        <li>Any unauthorized access to or use of our servers and/or any personal information stored therein</li>
                        <li>Any interruption or cessation of transmission to or from the service</li>
                        <li>Any bugs, viruses, trojan horses, or the like that may be transmitted to or through our service by any third party</li>
                        <li>Any errors or omissions in any content or for any loss or damage of any kind incurred as a result of your use of any content posted, emailed, transmitted, or otherwise made available via the service</li>
                    </ul>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">6. Disclaimer of Warranties</h2>
                    <p>EVAlarm provides the service on an "as is" and "as available" basis. We make no representations or warranties of any kind, express or implied, as to the operation of the service or the information, content, materials, or products included in the service. To the full extent permissible by applicable law, EVAlarm disclaims all warranties, express or implied, including but not limited to, implied warranties of merchantability and fitness for a particular purpose. EVAlarm does not warrant that the service, its servers, or email sent from EVAlarm are free of viruses or other harmful components.</p>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">7. Not Held Liable</h2>
                    <p>You expressly understand and agree that EVAlarm shall not be liable for any direct, indirect, incidental, special, consequential, or exemplary damages, including but not limited to, damages for loss of profits, goodwill, use, data or other intangible losses (even if EVAlarm has been advised of the possibility of such damages), resulting from:</p>
                    <ul className="list-disc pl-8 mt-2">
                        <li>The use or the inability to use the service</li>
                        <li>The cost of procurement of substitute goods and services resulting from any goods, data, information or services purchased or obtained or messages received or transactions entered into through or from the service</li>
                        <li>Unauthorized access to or alteration of your transmissions or data</li>
                        <li>Statements or conduct of any third party on the service</li>
                        <li>Any other matter relating to the service</li>
                    </ul>
                    <p className="mt-4">In no event shall EVAlarm's total liability to you for all damages, losses, and causes of action exceed the amount you have paid EVAlarm in the last six (6) months, or, if greater, one hundred dollars ($100).</p>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">8. Changes to Terms</h2>
                    <p>We reserve the right to modify these Terms of Service at any time. We will notify users of any significant changes via email or through the application.</p>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">9. Termination</h2>
                    <p>We reserve the right to terminate or suspend access to our service immediately, without prior notice or liability, for any reason whatsoever, including but not limited to a breach of the Terms.</p>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">10. Governing Law</h2>
                    <p>These Terms shall be governed and construed in accordance with the laws of United States and the State of New Jersey, without regard to its conflict of law provisions.</p>
                </section>

                <p className="text-sm text-gray-600 mt-12 italic">This is a revised version of the Terms of Service and may be subject to further updates. We recommend checking back regularly for any changes.</p>
            </div>
        </EVAlarmLayout>
    );
};

export default TermsOfService;