import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CheckCircle, Info } from 'lucide-react';
import { RootState } from '../../store';
import { setBillingCycle } from '../../store/plansSlice';
import EVAlarmLayout from "../layout/EVAlarmLayout";

export const PlanComparison: React.FC = () => {
    const dispatch = useDispatch();
    const plans = useSelector((state: RootState) => state.plans.plans);
    const billingCycle = useSelector((state: RootState) => state.plans.billingCycle);

    const handleBillingCycleChange = (cycle: 'monthly' | 'yearly') => {
        dispatch(setBillingCycle(cycle));
    };

    return (
        <EVAlarmLayout>
            <div className="py-20 md:py-32">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="sm:flex sm:flex-col sm:align-center mb-16">
                        <h1 className="text-5xl font-extrabold text-gray-900 sm:text-center">Pricing Plans</h1>
                        <p className="mt-5 text-xl text-gray-700 sm:text-center max-w-2xl mx-auto">
                            Choose the perfect plan for your EV charging needs. All plans come with a 60-day money-back guarantee!*
                        </p>
                        <p className="mt-2 text-sm text-gray-500 sm:text-center">
                            *60-day money-back guarantee applies only to plans purchased through our web app. App store purchases are subject to the respective store's refund policy.
                        </p>
                        <div className="relative self-center mt-8 bg-gray-100 rounded-lg p-0.5 flex sm:mt-10">
                            <button
                                type="button"
                                className={`${
                                    billingCycle === 'monthly' ? 'bg-white border-gray-200 shadow-sm text-gray-900' : 'border border-transparent text-gray-700'
                                } relative w-1/2 rounded-md py-2 text-sm font-medium whitespace-nowrap focus:outline-none focus:ring-2 focus:ring-teal-500 focus:z-10 sm:w-auto sm:px-8 transition duration-300`}
                                onClick={() => handleBillingCycleChange('monthly')}
                            >
                                Monthly billing
                            </button>
                            <button
                                type="button"
                                className={`${
                                    billingCycle === 'yearly' ? 'bg-white border-gray-200 shadow-sm text-gray-900' : 'border border-transparent text-gray-700'
                                } ml-0.5 relative w-1/2 rounded-md py-2 text-sm font-medium whitespace-nowrap focus:outline-none focus:ring-2 focus:ring-teal-500 focus:z-10 sm:w-auto sm:px-8 transition duration-300`}
                                onClick={() => handleBillingCycleChange('yearly')}
                            >
                                Yearly billing
                            </button>
                        </div>
                    </div>
                    <div className="mt-12 space-y-4 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:mx-0 xl:grid-cols-3">
                        {plans.map((plan) => (
                            <div key={plan.id} className="bg-white border border-gray-200 rounded-2xl shadow-lg divide-y divide-gray-200 transition duration-300 hover:shadow-xl hover:-translate-y-1">
                                <div className="p-6">
                                    <h2 className="text-2xl font-semibold text-gray-900 mb-4">{plan.name}</h2>
                                    <p className="text-gray-600 mb-8">{plan.description}</p>
                                    <p className="mb-8">
                                        <span className="text-4xl font-extrabold text-teal-600">
                                            ${billingCycle === 'monthly' ? plan.monthly_price : plan.yearly_price}
                                        </span>
                                        <span className="text-xl font-medium text-gray-500">/{billingCycle === 'monthly' ? 'mo' : 'year'}</span>
                                    </p>
                                    <a
                                        href={`/signup`}
                                        className="block w-full bg-teal-600 border border-transparent rounded-full py-3 text-base font-semibold text-white text-center hover:bg-teal-700 transition duration-300"
                                    >
                                        Get started
                                    </a>
                                </div>
                                <div className="pt-6 pb-8 px-6">
                                    <h3 className="text-sm font-medium text-gray-900 tracking-wide uppercase mb-4">What's included</h3>
                                    <ul className="space-y-4">
                                        {plan.features.map((feature, index) => (
                                            <li key={index} className="flex items-start">
                                                <CheckCircle className="flex-shrink-0 h-5 w-5 text-teal-500 mr-3 mt-0.5" aria-hidden="true"/>
                                                <span className="text-gray-600">{feature}</span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="mt-10 text-center">
                        <p className="text-sm text-gray-500 flex items-center justify-center">
                            <Info className="h-4 w-4 mr-2" />
                            60-day money-back guarantee applies only to plans purchased through our web app.
                            App store purchases are subject to the respective store's refund policy.
                        </p>
                    </div>
                </div>
            </div>
        </EVAlarmLayout>
    );
};