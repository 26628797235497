import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PaymentStep } from './PaymentStep';
import {RootState} from "../../../store";
import {checkRegistrationStatusRequest} from "../../../store/registrationSlice";

interface PaymentStepWrapperProps {
    sessionId: string;
    onPaymentSuccess: () => void;
    onPaymentError: (error: string) => void;
    onBack: () => void;
}

export const PaymentStepWrapper: React.FC<PaymentStepWrapperProps> = ({
                                                                          sessionId,
                                                                          onPaymentSuccess,
                                                                          onPaymentError,
                                                                          onBack
                                                                      }) => {
    const dispatch = useDispatch();
    const registration = useSelector((state: RootState) => state.registration);
    const isAuthenticated = useSelector((state: RootState) => state.user.isAuthenticated);

    useEffect(() => {
        const checkAuthStatus = () => {
            dispatch(checkRegistrationStatusRequest(sessionId));
        };

        // Check status immediately
        checkAuthStatus();

        // Then check every 5 seconds
        const intervalId = setInterval(checkAuthStatus, 5000);

        // Clear interval on component unmount
        return () => clearInterval(intervalId);
    }, [dispatch, sessionId]);

    useEffect(() => {
        if (registration.step === 'completed' && isAuthenticated) {
            // If registration is completed and user is authenticated,
            // we can assume the epic has handled the sign-in process
            onPaymentSuccess();
        }
    }, [registration.step, isAuthenticated, onPaymentSuccess]);

    if (!registration.clientSecret || !registration.paymentIntentId || !registration.currency || registration.totalAmount === null) {
        return <div>Loading payment details...</div>;
    }

    return (
        <PaymentStep
            clientSecret={registration.clientSecret}
            paymentIntentId={registration.paymentIntentId}
            currency={registration.currency}
            amountTotal={registration.totalAmount}
            onPaymentSuccess={onPaymentSuccess}
            onPaymentError={onPaymentError}
            onBack={onBack}
        />
    );
};