import React, { useState, useEffect } from 'react';
import { LogIn, ArrowLeft } from 'lucide-react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../common/Button';
import Input from '../common/Input';
import InlineLoadingIndicator from '../common/InlineLoadingIndicator';
import { signInRequest, otpRequest, resetOtpRequested } from '../../store/userSlice';
import { RootState } from "../../store";

const SignInForm: React.FC = () => {
    const { error, loading, otpRequested, pendingEmail } = useSelector((state: RootState) => state.user);

    const [email, setEmail] = useState(pendingEmail || '');
    const [password, setPassword] = useState('');
    const [otp, setOtp] = useState('');
    const [step, setStep] = useState('email');
    const [authType, setAuthType] = useState<'password' | 'otp'>('otp');
    const dispatch = useDispatch();

    useEffect(() => {
        if (pendingEmail && pendingEmail !== email) {
            setEmail(pendingEmail);
        }
    }, [pendingEmail]);


    useEffect(() => {
        if (otpRequested && step === 'email') {
            setStep('otp');
        }
    }, [otpRequested, step]);

    const handleAction = (action: 'submit' | 'sendOtp' | 'resendOtp') => {
        if (action === 'submit') {
            if (step === 'email') {
                if (authType === 'otp') {
                    dispatch(otpRequest({ email }));
                } else {
                    setStep('password');
                }
            } else if (step === 'otp' || step === 'password') {
                dispatch(signInRequest({ email, authType, password: authType === 'otp' ? otp : password }));
            }
        } else if (action === 'sendOtp' || action === 'resendOtp') {
            dispatch(otpRequest({ email }));
            if (action === 'resendOtp') {
                setOtp('');
            }
        }
    };

    const handleBack = () => {
        setStep('email');
        dispatch(resetOtpRequested());
    };

    const renderEmailStep = () => (
        <>
            <Input
                id="email"
                type="email"
                label="Email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
            />
            <div className="flex items-center space-x-2 mb-4">
                <input
                    type="checkbox"
                    id="usePassword"
                    checked={authType === 'password'}
                    onChange={() => setAuthType(authType === 'otp' ? 'password' : 'otp')}
                    className="form-checkbox"
                />
                <label htmlFor="usePassword" className="text-sm">
                    Sign in with password instead
                </label>
            </div>
            <Button
                onClick={() => handleAction('submit')}
                fullWidth
                disabled={loading || !email}
            >
                {loading ? <InlineLoadingIndicator /> : (authType === 'otp' ? 'Get sign-in code' : 'Continue')}
            </Button>
        </>
    );

    const renderOtpStep = () => (
        <>
            <p className="text-sm mb-4">
                We've sent a sign-in code to {email}
            </p>
            <Input
                id="otp"
                type="text"
                label="Enter sign-in code"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                required
            />
            <Button
                onClick={() => handleAction('submit')}
                fullWidth
                disabled={loading || otp.length < 6}
            >
                {loading ? <InlineLoadingIndicator /> : (
                    <>
                        <LogIn className="w-5 h-5 mr-2" />
                        Sign in
                    </>
                )}
            </Button>
            <Button
                onClick={() => handleAction('resendOtp')}
                disabled={loading}
            >
                {loading ? <InlineLoadingIndicator /> : 'Send new code'}
            </Button>
        </>
    );

    const renderPasswordStep = () => (
        <>
            <Input
                id="password"
                type="password"
                label="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
            />
            <Button
                onClick={() => handleAction('submit')}
                fullWidth
                disabled={loading}
            >
                {loading ? <InlineLoadingIndicator /> : (
                    <>
                        <LogIn className="w-5 h-5 mr-2" />
                        Sign in
                    </>
                )}
            </Button>
        </>
    );

    return (
        <div className="space-y-6">
            {step !== 'email' && (
                <Button onClick={handleBack} className="mb-4">
                    <ArrowLeft className="w-4 h-4 mr-2" />
                    Back
                </Button>
            )}

            {step === 'email' && renderEmailStep()}
            {step === 'otp' && renderOtpStep()}
            {step === 'password' && renderPasswordStep()}

            {error && <p className="text-red-500 text-sm mt-2">{error}</p>}

            {step === 'email' && (
                <p className="text-center text-sm mt-4">
                    Don't have an account?{' '}
                    <a href="/signup" className="font-medium text-blue-600 hover:underline">
                        Sign up
                    </a>
                </p>
            )}
        </div>
    );
};

export default SignInForm;