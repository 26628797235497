import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { updateRegistrationSessionRequest } from "../../../store/registrationSlice";

const FormButton: React.FC<React.ButtonHTMLAttributes<HTMLButtonElement> & { variant?: 'primary' | 'secondary' }> = ({ children, variant = 'primary', ...props }) => (
    <button
        {...props}
        className={`w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 disabled:opacity-50 ${
            variant === 'primary'
                ? 'text-white bg-teal-600 hover:bg-teal-700'
                : 'text-teal-700 bg-teal-100 hover:bg-teal-200'
        }`}
    >
        {children}
    </button>
);

export const PlanSelectionStep: React.FC<{
    selectedPlanId: string;
    setSelectedPlanId: (planId: string) => void;
    selectedBillingFrequency: 'monthly' | 'yearly';
    setSelectedBillingFrequency: (frequency: 'monthly' | 'yearly') => void;
    onNext: () => void;
    onBack: () => void;
}> = ({ selectedPlanId, setSelectedPlanId, selectedBillingFrequency, setSelectedBillingFrequency, onNext, onBack }) => {
    const dispatch = useDispatch();
    const { sessionId, loading, error } = useSelector((state: RootState) => state.registration);
    const { plans } = useSelector((state: RootState) => state.plans);

    // Local state to track changes
    const [localPlanId, setLocalPlanId] = useState(selectedPlanId);
    const [localBillingFrequency, setLocalBillingFrequency] = useState(selectedBillingFrequency);

    const handlePlanChange = (planId: string) => {
        setLocalPlanId(planId);
    };

    const handleBillingFrequencyChange = (frequency: 'monthly' | 'yearly') => {
        setLocalBillingFrequency(frequency);
    };

    const handleNext = () => {
        // Update the parent component's state
        setSelectedPlanId(localPlanId);
        setSelectedBillingFrequency(localBillingFrequency);

        // Dispatch the action to update the registration session
        dispatch(updateRegistrationSessionRequest({
            sessionId: sessionId!,
            step: 'plan_selection',
            planId: localPlanId,
            billingFrequency: localBillingFrequency
        }));

        // Call the onNext function passed from the parent
        onNext();
    };

    return (
        <div className="space-y-6">
            <h2 className="text-2xl font-bold text-gray-900">Select a Plan</h2>
            <div className="space-y-4">
                {plans.map((plan) => (
                    <div key={plan.id} className="flex items-center">
                        <input
                            type="radio"
                            id={plan.id}
                            name="plan"
                            value={plan.id}
                            checked={localPlanId === plan.id}
                            onChange={() => handlePlanChange(plan.id)}
                            className="h-4 w-4 text-teal-600 focus:ring-teal-500 border-gray-300"
                        />
                        <label htmlFor={plan.id} className="ml-3 block text-sm font-medium text-gray-700">
                            {plan.name} - ${localBillingFrequency === 'monthly' ? plan.monthly_price : plan.yearly_price}/{localBillingFrequency}
                        </label>
                    </div>
                ))}
            </div>
            <div className="flex space-x-4">
                <button
                    onClick={() => handleBillingFrequencyChange('monthly')}
                    className={`flex-1 py-2 px-4 border rounded-md text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 ${
                        localBillingFrequency === 'monthly'
                            ? 'bg-teal-600 text-white hover:bg-teal-700'
                            : 'bg-white text-gray-700 hover:bg-gray-50'
                    }`}
                    disabled={localBillingFrequency === 'monthly'}
                >
                    Monthly
                </button>
                <button
                    onClick={() => handleBillingFrequencyChange('yearly')}
                    className={`flex-1 py-2 px-4 border rounded-md text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 ${
                        localBillingFrequency === 'yearly'
                            ? 'bg-teal-600 text-white hover:bg-teal-700'
                            : 'bg-white text-gray-700 hover:bg-gray-50'
                    }`}
                    disabled={localBillingFrequency === 'yearly'}
                >
                    Yearly
                </button>
            </div>
            {error && <p className="mt-2 text-sm text-red-600">{error}</p>}
            <div className="flex space-x-4">
                <FormButton onClick={onBack} variant="secondary">Back</FormButton>
                <FormButton onClick={handleNext} disabled={!localPlanId || loading}>Next</FormButton>
            </div>
        </div>
    );
};