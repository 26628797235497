import React, { ReactNode } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY);

interface StripeWrapperProps {
    clientSecret?: string | null;
    children: ReactNode;
}

const StripeWrapper: React.FC<StripeWrapperProps> = ({ clientSecret, children }) => {
    const registrationClientSecret = useSelector((state: RootState) => state.registration.clientSecret);

    if (registrationClientSecret) {
        clientSecret = registrationClientSecret
    }

    if (typeof clientSecret !== 'undefined') {
        return <div>Preparing payment system...</div>;
    }

    return (
        <Elements
            stripe={stripePromise}
            options={{
                clientSecret,
                appearance: { theme: 'stripe' },
            }}
        >
            {children}
        </Elements>
    );
};

export default StripeWrapper;