import { of, from } from 'rxjs';
import { mergeMap, map, catchError, filter } from 'rxjs/operators';
import {
    fetchSubscriptionStart,
    fetchSubscriptionSuccess,
    fetchSubscriptionFailure,
    updatePlanStart,
    updatePlanSuccess,
    updatePlanFailure,
    updatePaymentMethodStart,
    updatePaymentMethodSuccess,
    updatePaymentMethodFailure,
    createSubscriptionStart,
    createSubscriptionSuccess,
    createSubscriptionFailure,
    estimatePriceStart,
    estimatePriceSuccess,
    estimatePriceFailure,
    cancelSubscriptionStart,
    cancelSubscriptionSuccess,
    cancelSubscriptionFailure,
    reactivateSubscriptionStart,
    reactivateSubscriptionSuccess,
    reactivateSubscriptionFailure,
} from '../subscriptionSlice';
import {AppEpic, getAuthHeaders} from './index';
import { combineEpics } from "redux-observable";

const fetchSubscriptionEpic: AppEpic = (action$) =>
    action$.pipe(
        filter(fetchSubscriptionStart.match),
        mergeMap(() =>
            from(fetch('/api/subscription', {
                method: 'GET',
                headers: getAuthHeaders()
            })).pipe(
                mergeMap(response => {
                    if (response.status === 404) {
                        throw new Error('Subscription not found');
                    }
                    return from(response.json());
                }),
                map(data => fetchSubscriptionSuccess(data)),
                catchError(error => {
                    if (error.message === 'Subscription not found') {
                        return of(fetchSubscriptionFailure('Subscription not found'));
                    }
                    return of(fetchSubscriptionFailure(error.message));
                })
            )
        )
    );

const updatePlanEpic: AppEpic = (action$) =>
    action$.pipe(
        filter(updatePlanStart.match),
        mergeMap((action) =>
            from(fetch('/api/subscription', {
                method: 'PUT',
                headers: {
                    ...getAuthHeaders(),
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    plan_id: action.payload.planId,
                    billing_frequency: action.payload.billingFrequency
                })
            })).pipe(
                mergeMap(response => from(response.json())),
                map(data => updatePlanSuccess(data)),
                catchError(error => of(updatePlanFailure(error.message)))
            )
        )
    );

const updatePaymentMethodEpic: AppEpic = (action$) =>
    action$.pipe(
        filter(updatePaymentMethodStart.match),
        mergeMap((action) =>
            from(fetch('/api/subscription/update_payment_method', {
                method: 'POST',
                headers: {
                    ...getAuthHeaders(),
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ payment_method_id: action.payload.paymentMethodId })
            })).pipe(
                mergeMap(response => from(response.json())),
                map(data => updatePaymentMethodSuccess(data)),
                catchError(error => of(updatePaymentMethodFailure(error.message)))
            )
        )
    );

const createSubscriptionEpic: AppEpic = (action$) =>
    action$.pipe(
        filter(createSubscriptionStart.match),
        mergeMap((action) =>
            from(fetch('/api/subscription', {
                method: 'POST',
                headers: {
                    ...getAuthHeaders(),
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    plan_id: action.payload.planId,
                    payment_method_id: action.payload.paymentMethodId,
                    billing_frequency: action.payload.billingFrequency
                })
            })).pipe(
                mergeMap(response => from(response.json())),
                map(data => createSubscriptionSuccess(data)),
                catchError(error => of(createSubscriptionFailure(error.message)))
            )
        )
    );

const estimatePriceEpic: AppEpic = (action$) =>
    action$.pipe(
        filter(estimatePriceStart.match),
        mergeMap((action) =>
            from(fetch(`/api/subscription/estimate_price?plan_id=${action.payload.planId}&billing_frequency=${action.payload.billingFrequency}`, {
                method: 'GET',
                headers: getAuthHeaders()
            })).pipe(
                mergeMap(response => from(response.json())),
                map(data => estimatePriceSuccess(data)),
                catchError(error => of(estimatePriceFailure(error.message)))
            )
        )
    );

const cancelSubscriptionEpic: AppEpic = (action$) =>
    action$.pipe(
        filter(cancelSubscriptionStart.match),
        mergeMap((action) =>
            from(fetch('/api/subscription/cancel', {
                method: 'POST',
                headers: {
                    ...getAuthHeaders(),
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(action.payload),
            })).pipe(
                mergeMap(response => from(response.json())),
                map(data => cancelSubscriptionSuccess(data)),
                catchError(error => of(cancelSubscriptionFailure(error.message)))
            )
        )
    );

const reactivateSubscriptionEpic: AppEpic = (action$) =>
    action$.pipe(
        filter(reactivateSubscriptionStart.match),
        mergeMap(() =>
            from(fetch('/api/subscription/reactivate', {
                method: 'POST',
                headers: getAuthHeaders()
            })).pipe(
                mergeMap(response => from(response.json())),
                map(data => reactivateSubscriptionSuccess(data)),
                catchError(error => of(reactivateSubscriptionFailure(error.message)))
            )
        )
    );

const subscriptionEpics = combineEpics(
    cancelSubscriptionEpic,
    fetchSubscriptionEpic,
    updatePlanEpic,
    updatePaymentMethodEpic,
    createSubscriptionEpic,
    estimatePriceEpic,
    reactivateSubscriptionEpic,
);

export default subscriptionEpics;