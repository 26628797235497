import { useState, useEffect } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { Dispatch } from 'redux';
import {Plan} from "../store/plansSlice";
import {createSubscriptionStart, updatePaymentMethodStart} from "../store/subscriptionSlice";

interface UseSubscriptionFormProps {
    dispatch: Dispatch;
    hasSubscription: boolean;
    currentPlan: string | null;
    plans: Plan[];
    billingCycle: 'monthly' | 'yearly';
}

export const useSubscriptionForm = ({
                                        dispatch,
                                        hasSubscription,
                                        currentPlan,
                                        plans,
                                        billingCycle
                                    }: UseSubscriptionFormProps) => {
    const [selectedPlanId, setSelectedPlanId] = useState<string>('');
    const [paymentName, setPaymentName] = useState<string>('');
    const [showPaymentForm, setShowPaymentForm] = useState<boolean>(false);

    const stripe = useStripe();
    const elements = useElements();

    useEffect(() => {
        if (currentPlan) {
            setSelectedPlanId(plans.find(plan => plan.name === currentPlan)?.id || '');
        }
    }, [currentPlan, plans]);

    const handlePlanChange = (planId: string) => {
        setSelectedPlanId(planId);
        if (!hasSubscription) {
            setShowPaymentForm(true);
        }
    };

    const handlePaymentNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPaymentName(e.target.value);
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!stripe || !elements) {
            alert("Stripe has not loaded yet.");
            return;
        }

        const cardElement = elements.getElement(CardElement);
        if (!cardElement) {
            alert("Unable to process payment.");
            return;
        }

        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
            billing_details: { name: paymentName },
        });

        if (error) {
            alert(error.message);
            return;
        }

        if (paymentMethod) {
            dispatch(createSubscriptionStart({
                planId: selectedPlanId,
                paymentMethodId: paymentMethod.id,
                billingFrequency: billingCycle
            }));
        }
    };

    const handleUpdatePaymentMethod = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!stripe || !elements) {
            alert("Stripe has not loaded yet.");
            return;
        }

        const cardElement = elements.getElement(CardElement);
        if (!cardElement) {
            alert("Unable to process payment.");
            return;
        }

        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
            billing_details: { name: paymentName },
        });

        if (error) {
            alert(error.message);
            return;
        }

        if (paymentMethod) {
            dispatch(updatePaymentMethodStart({ paymentMethodId: paymentMethod.id }));
        }
    };

    return {
        selectedPlanId,
        paymentName,
        showPaymentForm,
        handlePlanChange,
        handlePaymentNameChange,
        handleSubmit,
        handleUpdatePaymentMethod,
        setShowPaymentForm
    };
};