import React from 'react';

const LogoIcon = ({ size = 48}) => (
    <svg width={size} height={size} viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20 30L50 15L80 30L50 45L20 30Z" fill="#14B8A6"/>
        <path d="M20 30L50 45L50 75L20 60L20 30Z" fill="#B2F5EA"/>
        <path d="M50 45L80 30L80 60L50 75L50 45Z" fill="#14B8A6"/>
    </svg>
);

export default LogoIcon;