import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {clearCurrentTicket, createTicketRequest, HelpdeskTicket} from "../../../store/helpdeskSlice";
import {AppDispatch, RootState} from "../../../store";

const CreateTicket: React.FC = () => {
    const [subject, setSubject] = useState('');
    const [description, setDescription] = useState('');
    const [guestEmail, setGuestEmail] = useState('');

    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const isAuthenticated = useSelector((state: RootState) => state.user.isAuthenticated);
    const currentUser = useSelector((state: RootState) => state.user.currentUser);
    const { loading, error, currentTicket } = useSelector((state: RootState) => state.helpdesk);

    useEffect(() => {
        if (currentTicket) {
            navigate(`/help/ticket/confirmation/${currentTicket.id}`);
            dispatch(clearCurrentTicket());
        }
    }, [currentTicket, navigate, dispatch]);

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        const ticketData: Omit<HelpdeskTicket, 'id' | 'created_at' | 'updated_at' | 'comments' | 'attachments'> = {
            title: subject,
            description,
            status: 'open',
            requester_email: isAuthenticated ? currentUser!.email : guestEmail,
        };
        dispatch(createTicketRequest(ticketData));
    };

    const isValidEmail = (email: string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const isFormValid = isAuthenticated || (guestEmail && isValidEmail(guestEmail));

    return (
        <form onSubmit={handleSubmit} className="space-y-6">
            {/* Form fields remain the same */}
            {!isAuthenticated && (
                <div>
                    <label htmlFor="guestEmail" className="block text-sm font-medium text-gray-700">
                        Your Email Address
                    </label>
                    <input
                        type="email"
                        id="guestEmail"
                        value={guestEmail}
                        onChange={(e) => setGuestEmail(e.target.value)}
                        required
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-teal-500 focus:border-teal-500"
                    />
                    {guestEmail && !isValidEmail(guestEmail) && (
                        <p className="mt-2 text-sm text-red-600">Please enter a valid email address.</p>
                    )}
                </div>
            )}
            <div>
                <label htmlFor="subject" className="block text-sm font-medium text-gray-700">
                    Subject
                </label>
                <input
                    type="text"
                    id="subject"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    required
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-teal-500 focus:border-teal-500"
                />
            </div>
            <div>
                <label htmlFor="description" className="block text-sm font-medium text-gray-700">
                    Description
                </label>
                <textarea
                    id="description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    required
                    rows={4}
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-teal-500 focus:border-teal-500"
                />
            </div>
            <div>
                <button
                    type="submit"
                    disabled={!isFormValid || loading}
                    className={`w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white ${
                        isFormValid && !loading
                            ? 'bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500'
                            : 'bg-gray-400 cursor-not-allowed'
                    }`}
                >
                    {loading ? 'Submitting...' : 'Submit Ticket'}
                </button>
            </div>
            {error && (
                <p className="text-sm text-red-600 mt-2">
                    Error submitting ticket: {error}
                </p>
            )}
            {!isAuthenticated && (
                <p className="text-sm text-gray-600 mt-2">
                    Note: You're submitting this ticket as a guest. To track your tickets, please{' '}
                    <a href="/signin" className="text-teal-600 hover:text-teal-800">
                        sign in
                    </a>
                    .
                </p>
            )}
        </form>
    );
};

export default CreateTicket;