import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface Plan {
    id: string;
    name: string;
    description: string;
    monthly_price: number;
    yearly_price: number;
    max_vehicles: number;
    max_devices: number;
    features: string[];
}

export interface PlansState {
    plans: Plan[];
    billingCycle: 'monthly' | 'yearly';
}

function getPlansFromMetaTag(): Plan[] {
    const plansMeta = document.querySelector('meta[name="plans-data"]');
    if (plansMeta && plansMeta.getAttribute('content')) {
        return JSON.parse(plansMeta.getAttribute('content')!);
    }
    return [];
}

const initialState: PlansState = {
    plans: getPlansFromMetaTag(),
    billingCycle: 'monthly',
};

const plansSlice = createSlice({
    name: 'plans',
    initialState,
    reducers: {
        setBillingCycle(state, action: PayloadAction<'monthly' | 'yearly'>) {
            state.billingCycle = action.payload;
        },
    },
});

export const { setBillingCycle } = plansSlice.actions;
export default plansSlice.reducer;