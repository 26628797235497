import React, { useState, useEffect, useCallback } from 'react';
import { Car, Battery, X, Bell, DollarSign } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';

// Demo speed multiplier (1 = normal speed, 2 = twice as fast, etc.)
const DEMO_SPEED = 2;

const INIT_BATTERY_LEVEL = 30;
const DEMO_DURATION = 30 / DEMO_SPEED; // Demo runs for 60 seconds / DEMO_SPEED
const INITIAL_TIME = 50 * 60; // Start at 20 minutes
const TIME_SCALE = (INITIAL_TIME / DEMO_DURATION) * DEMO_SPEED;
const CHARGING_RATE = ((70 - INIT_BATTERY_LEVEL) / DEMO_DURATION) * DEMO_SPEED; // Adjust charging rate with speed
const NOTIFICATION_INTERVAL = 1000 / DEMO_SPEED; // Notification every second, adjusted for demo speed
const FEE_RATE = DEMO_SPEED; // $1 per second, adjusted for demo speed

interface Notification {
    id: number;
    message: string;
    urgent: boolean;
}

const ProductDemo = () => {
    const [batteryLevel, setBatteryLevel] = useState(INIT_BATTERY_LEVEL);
    const targetCharge = 70; // Fixed target charge at 70%
    const [estimatedTimeToTarget, setEstimatedTimeToTarget] = useState(INITIAL_TIME);
    const [reminderTime, setReminderTime] = useState(10); // Default 10 minutes before target charge
    const [notifications, setNotifications] = useState<Notification[]>([]);
    const [isCharging, setIsCharging] = useState(false);
    const [notificationStarted, setNotificationStarted] = useState(false);
    const [demoPhase, setDemoPhase] = useState<'charging' | 'completed'>('charging');
    const [overstayFees, setOverstayFees] = useState(0);

    const addNotification = useCallback(() => {
        const urgent = demoPhase === 'completed';
        const newNotification: Notification = {
            id: Date.now(),
            message: urgent
                ? `URGENT: Your Tesla is fully charged at ${batteryLevel.toFixed(1)}%. Move your car now to avoid overstay fees!`
                : `Your Tesla is at ${batteryLevel.toFixed(1)}%. Please prepare to disconnect the charger soon.`,
            urgent: urgent
        };
        setNotifications(prev => [...prev.slice(-4), newNotification]); // Keep last 5 notifications
    }, [batteryLevel, demoPhase]);

    useEffect(() => {
        let timer: number | undefined;

        if (isCharging) {
            timer = window.setInterval(() => {
                setBatteryLevel(prevLevel => {
                    const newLevel = Math.min(prevLevel + CHARGING_RATE, targetCharge);
                    if (newLevel >= targetCharge) {
                        setDemoPhase('completed');
                    }
                    return newLevel;
                });
                setEstimatedTimeToTarget(prev => Math.max(0, prev - TIME_SCALE));

                if (notificationStarted) {
                    addNotification();
                }

                if (demoPhase === 'completed') {
                    setOverstayFees(prev => prev + FEE_RATE);
                }
            }, NOTIFICATION_INTERVAL);
        }

        return () => {
            clearInterval(timer);
        };
    }, [isCharging, targetCharge, notificationStarted, demoPhase, addNotification]);

    useEffect(() => {
        const timeToTargetInMinutes = estimatedTimeToTarget / 60;
        if (isCharging && timeToTargetInMinutes <= reminderTime && !notificationStarted) {
            setNotificationStarted(true);
            addNotification();
        }
    }, [isCharging, estimatedTimeToTarget, reminderTime, notificationStarted, addNotification]);

    const formatTime = (seconds: number): string => {
        const mins = Math.floor(seconds / 60);
        return `${mins}m`;
    };

    const removeNotification = (id: number) => {
        setNotifications(prev => prev.filter(notif => notif.id !== id));
    };

    const resetDemo = () => {
        setBatteryLevel(INIT_BATTERY_LEVEL);
        setEstimatedTimeToTarget(INITIAL_TIME);
        setNotifications([]);
        setIsCharging(false);
        setNotificationStarted(false);
        setDemoPhase('charging');
        setOverstayFees(0);
    };

    const toggleCharging = () => {
        setIsCharging(prev => !prev);
        if (!isCharging) {
            setNotifications([]);
            setNotificationStarted(false);
            setDemoPhase('charging');
            setOverstayFees(0);
            if ((INITIAL_TIME / 60) <= reminderTime) {
                setNotificationStarted(true);
                addNotification();
            }
        }
    };

    return (
        <div className="bg-white rounded-3xl shadow-xl p-8 max-w-4xl mx-auto">
            <div className="fixed top-4 right-4 z-50 flex flex-col items-end space-y-2 max-w-sm w-full">
                <AnimatePresence>
                    {notifications.map((notif) => (
                        <motion.div
                            key={notif.id}
                            initial={{ opacity: 0, x: 100 }}
                            animate={{ opacity: 1, x: 0 }}
                            exit={{ opacity: 0, x: 100 }}
                            className={`${
                                notif.urgent ? 'bg-red-600' : 'bg-teal-600'
                            } text-white px-4 py-2 rounded-lg shadow-lg flex items-center justify-between w-full`}
                        >
                            <div className="flex items-center">
                                <Bell className="mr-2" size={18} />
                                <p className="text-sm">{notif.message}</p>
                            </div>
                            <button
                                onClick={() => removeNotification(notif.id)}
                                className="text-white hover:text-gray-300 ml-2"
                            >
                                <X size={14} />
                            </button>
                        </motion.div>
                    ))}
                </AnimatePresence>
            </div>
            <h3 className="text-2xl font-bold mb-6 text-gray-800">EVAlarm Demo: Prevent Overstay Fees</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                <div className="bg-gray-100 p-6 rounded-xl">
                    <div className="flex justify-between items-center mb-4">
                        <Car className="text-teal-600" size={32} />
                        <div className="text-right">
                            <p className="text-sm text-gray-600">Est. Time to 70% Charge</p>
                            <p className="text-2xl font-bold text-teal-600">{formatTime(estimatedTimeToTarget)}</p>
                        </div>
                    </div>
                    <div className="mb-4">
                        <div className="flex justify-between mb-2">
                            <span className="text-sm text-gray-600">Battery Level</span>
                            <span className="text-sm font-semibold">{batteryLevel.toFixed(1)}%</span>
                        </div>
                        <div className="w-full bg-gray-300 rounded-full h-2.5 relative">
                            <div
                                className="bg-teal-600 h-2.5 rounded-full transition-all duration-500 ease-out"
                                style={{ width: `${batteryLevel}%` }}
                            ></div>
                            <div
                                className="absolute top-0 bottom-0 w-0.5 bg-red-500"
                                style={{ left: `${targetCharge}%` }}
                            ></div>
                        </div>
                    </div>
                    <div className="mb-4">
                        <div className="flex justify-between mb-2">
                            <span className="text-sm text-gray-600">Notify me (minutes before target)</span>
                            <span className="text-sm font-semibold">{reminderTime} min</span>
                        </div>
                        <input
                            type="range"
                            min={0}
                            max={20}
                            value={reminderTime}
                            onChange={(e) => setReminderTime(Number(e.target.value))}
                            className="w-full"
                        />
                    </div>
                    <div className="flex justify-between items-center">
                        <div className="flex items-center">
                            <Battery className={`${batteryLevel >= targetCharge ? 'text-green-500' : 'text-yellow-500'}`} size={24} />
                            <span className="ml-2 text-sm text-gray-600">
                                {batteryLevel < targetCharge
                                    ? `Charging to ${targetCharge}%`
                                    : 'Target charge reached'}
                            </span>
                        </div>
                        <button
                            onClick={toggleCharging}
                            className={`${isCharging ? 'bg-red-500 hover:bg-red-600' : 'bg-teal-600 hover:bg-teal-700'} text-white px-4 py-2 rounded-full text-sm transition duration-300`}
                        >
                            {isCharging ? 'Stop Charging' : 'Start Charging'}
                        </button>
                    </div>
                </div>
                <div>
                    <div className="bg-gray-100 p-4 rounded-xl mb-4">
                        <p className="text-sm text-gray-600 mb-2">EVAlarm Status:</p>
                        <p className="text-gray-700">
                            {isCharging
                                ? notificationStarted
                                    ? `EVAlarm active! You're receiving notifications to disconnect the charger and move your car.`
                                    : `Charging in progress. EVAlarm will start alerting you ${reminderTime} minutes before reaching ${targetCharge}%.`
                                : demoPhase === 'completed'
                                    ? "Charging complete! EVAlarm is sending urgent notifications to move your car."
                                    : "Charger disconnected. EVAlarm is on standby."}
                        </p>
                    </div>
                    {demoPhase === 'completed' && isCharging && (
                        <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-4 animate-pulse" role="alert">
                            <p className="font-bold">Overstay Fees Accruing Rapidly!</p>
                            <p className="flex items-center">
                                <DollarSign className="mr-2" size={18} />
                                Current fees: ${overstayFees.toFixed(2)}
                            </p>
                        </div>
                    )}
                    <div className="mt-4">
                        <button
                            onClick={resetDemo}
                            className="bg-teal-600 text-white px-4 py-2 rounded-full text-sm hover:bg-teal-700 transition duration-300"
                        >
                            Reset Demo
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductDemo;