import React from 'react';
import { Link } from 'react-router-dom';
import {ArticleCategory, KnowledgeBase} from "./knowledge_base_types";

const initialArticles = [
    {
        id: "create-account",
        title: 'How to create an account',
        content: (
            <>
                <p>You can create your EVAlarm account either through our app or on our web platform:</p>
                <ol>
                    <li>Choose your preferred platform (app or web)</li>
                    <li>Enter your email address</li>
                    <li>Choose between creating a password or verifying with a 6-digit code</li>
                    <li>Select a subscription plan</li>
                    <li>Complete the payment for your initial subscription</li>
                </ol>
                <p><strong>Note:</strong> We offer a 90-day money-back guarantee with no questions asked if you're not satisfied with our service.</p>
                <p>For more details on our pricing plans, please visit our <Link to="/plans">Plans Page</Link>.</p>
            </>
        ),
        category: ArticleCategory.AccountAndSignUp,
    },
    {
        id: "first-alarm",
        title: 'Setting up your first alarm',
        content: (
            <>
                <p>To set up your first alarm with EVAlarm, follow these steps:</p>
                <ol>
                    <li>Open the EVAlarm app on your device</li>
                    <li>You'll be prompted to associate your Tesla account with our platform</li>
                    <li>Click on the link to be redirected to the Tesla login page</li>
                    <li>Log in to your Tesla account</li>
                    <li>Grant permission for EVAlarm to access your charging data</li>
                </ol>
                <p><strong>Important:</strong> The permissions we request only allow us to collect charging data and adjust charging voltages to your preference. We cannot control any other aspects of your vehicle.</p>
                <p>For more information on supported vehicles, please check our <Link to="/help/article/supported-vehicles">Supported Vehicles</Link> guide.</p>
            </>
        ),
        category: ArticleCategory.App,
    },
    {
        id: "supported-devices",
        title: 'Supported Devices',
        content: (
            <>
                <h2>Supported Devices</h2>
                <p>EVAlarm is currently available on the following platforms:</p>
                <ul>
                    <li><strong>iOS:</strong> Our app is fully supported on iOS devices running iOS 13.0 or later. This includes iPhone, iPad, and iPod touch.</li>
                </ul>
                <h3>Future Support</h3>
                <p>We're committed to expanding our platform support to reach more users. Here's our roadmap for future device support:</p>
                <ul>
                    <li><strong>Android:</strong> Android support is in development and will be our next platform release. We're working hard to bring EVAlarm to Android users as soon as possible.</li>
                    <li><strong>Desktop:</strong> Desktop support for Windows, macOS, and Linux is planned for a future release. This is a longer-term project and will come after our Android release.</li>
                </ul>
                <p><strong>Note:</strong> While we don't have specific release dates for Android and desktop versions, we're actively working on expanding our platform support. Please check back on this article periodically for the latest updates on new platform releases.</p>
                <p>If you have any questions about device support or would like to request support for a specific platform, please don't hesitate to <Link to="/help/ticket">contact our support team</Link>.</p>
            </>
        ),
        category: ArticleCategory.App,
    },
    {
        id: "pricing-plans",
        title: 'Understanding our pricing plans',
        content: (
            <>
                <p>For detailed information about our pricing plans and features, please visit our <Link to="/plans">Plans Page</Link>.</p>
            </>
        ),
        category: ArticleCategory.BillingAndPlans,
    },
    {
        id: "supported-vehicles",
        title: 'Supported Vehicles',
        content: (
            <>
                <h2>Supported Vehicles</h2>
                <p>EVAlarm currently supports almost all Tesla models, with some exceptions:</p>
                <ul>
                    <li>We support all current Tesla models (yes, even that fancy Cybertruck you're still waiting for)</li>
                    <li>The early Tesla Roadster is not supported (if you have one of these, we'd be more interested in how you time-traveled from 2008)</li>
                    <li>Some limited real-time functionality is only available for vehicles produced in 2021 and later (because they speak a fancier machine language)</li>
                </ul>
                <p>We're continuously working to expand our support for more vehicles and enhance our features. Check back regularly for updates!</p>
                <p><strong>Note:</strong> If you actually own an original Tesla Roadster, we'd be surprised (and a little jealous). Feel free to <Link to="/help/article/contact-support">contact us</Link> and show it off. We can't promise to support it, but we'd love to see pictures!</p>
            </>
        ),
        category: ArticleCategory.App,
    },
    {
        id: "change-cancel-subscription",
        title: 'Changing or Cancelling Your Subscription',
        content: (
            <>
                <h2>Changing or Cancelling Your Subscription</h2>
                <h3>Web Platform:</h3>
                <ol>
                    <li>Go to the Settings section</li>
                    <li>Navigate to Plans & Payments</li>
                    <li>Choose to upgrade, downgrade, or end your plan</li>
                </ol>
                <p><strong>Note:</strong> If you downgrade, you'll remain on your current tier until it ends. If you cancel, you'll have access to our service until the end of the billing period.</p>

                <h3>iOS App:</h3>
                <ol>
                    <li>Open the EVAlarm iOS app</li>
                    <li>Go to the Settings tab</li>
                    <li>Select Manage Subscription</li>
                </ol>
                <p>You can also manage your subscription through the App Store app.</p>
                <p><strong>Important:</strong> Due to Apple's restrictions, subscription changes for iOS users must be made through our iOS app or the App Store.</p>
            </>
        ),
        category: ArticleCategory.BillingAndPlans,
    },
    {
        id: "refund-policy",
        title: 'Our Refund Policy',
        content: (
            <>
                <h2>EVAlarm Refund Policy</h2>
                <p>We want you to be completely satisfied with our service. Our refund policy varies depending on where you made your purchase:</p>

                <h3>Subscriptions Purchased on Our Website</h3>
                <ul>
                    <li>We offer a 60-day, no-questions-asked refund policy for subscriptions purchased directly through our website.</li>
                    <li>To request a refund, simply send a refund request to our support team within 60 days of your purchase.</li>
                    <li>You can initiate this process by <Link to="/help/ticket">creating a support ticket</Link> or emailing us at <a href="mailto:new-ticket@inbound.evalarm.app">new-ticket@inbound.evalarm.app</a>.</li>
                </ul>

                <h3>Subscriptions Purchased through the App Store</h3>
                <ul>
                    <li>For purchases made through the Apple App Store, refunds are handled directly by Apple.</li>
                    <li>Due to App Store policies, we are unable to process these refunds ourselves.</li>
                    <li>To request a refund for an App Store purchase, please follow Apple's refund process:</li>
                    <ol>
                        <li>Visit <a href="https://reportaproblem.apple.com/">reportaproblem.apple.com</a></li>
                        <li>Sign in with your Apple ID</li>
                        <li>Select the subscription you wish to refund</li>
                        <li>Follow the prompts to request your refund</li>
                    </ol>
                    <li>Please note that Apple's refund policy may differ from our website policy, and we have no control over their decisions regarding refunds.</li>
                </ul>

                <p>If you have any questions about our refund policy, please don't hesitate to <Link to="/help/ticket">contact our support team</Link>.</p>
            </>
        ),
        category: ArticleCategory.BillingAndPlans,
    },
    {
        id: "account-deletion",
        title: 'How to Delete Your Account',
        content: (
            <>
                <h2>How to Delete Your Account</h2>
                <p>To delete your account, please follow these steps:</p>
                <ol>
                    <li>First, cancel your subscription (if active) using the instructions in our <Link to="/help/article/change-cancel-subscription">Changing or Cancelling Your Subscription</Link> guide.</li>
                    <li>Contact our support team by either:
                        <ul>
                            <li>Creating a ticket in our ticket creation system, or</li>
                            <li>Emailing us at <a href="mailto:new-ticket@inbound.evalarm.app">new-ticket@inbound.evalarm.app</a></li>
                        </ul>
                    </li>
                    <li>Request account deletion in your message</li>
                </ol>
                <p><strong>Note:</strong> Due to restrictions by Apple, we cannot cancel subscriptions on behalf of iOS users. Please ensure you've cancelled your subscription before requesting account deletion.</p>
            </>
        ),
        category: ArticleCategory.AccountAndSignUp,
    },
    {
        id: "data-protection",
        title: 'How we protect your data',
        content: (
            <>
                <h2>How We Protect Your Data</h2>
                <p>At EVAlarm.app, we believe in maximum privacy with minimal data collection. Here's our approach:</p>
                <ul>
                    <li><strong>Minimal Data Collection:</strong> We collect only the bare minimum of data necessary to provide you with the best possible service.</li>
                    <li><strong>No Third-Party Analytics:</strong> We do not work with any third-party analytics providers, ensuring your data stays within our secure ecosystem.</li>
                    <li><strong>Payment Privacy:</strong> Our payment partners do not share your payment details with us, maintaining a strict separation between financial and user data.</li>
                    <li><strong>Anonymity First:</strong> We don't collect or store your name, and we prefer to keep it that way. Your privacy is our priority.</li>
                    <li><strong>Legal Compliance:</strong> We fully comply with major data privacy legislations, including GDPR, CCPA, and various other data privacy laws.</li>
                    <li><strong>Right to be Forgotten</strong>: We honor all requests for account deletion in accordance with applicable privacy laws.</li>
                </ul>
                <p>For more details on how we handle your data, please review our full <Link to="/privacy">Privacy Policy</Link>.</p>
            </>
        ),
        category: ArticleCategory.PrivacyAndData,
    },
    {
        id: "contact-support",
        title: 'Ways to reach our support team',
        content: (
            <>
                <h2>Ways to Reach Our Support Team</h2>
                <p>Need help? We're here to assist you. Here are the ways you can get in touch with our support team:</p>
                <ol>
                    <li>
                        <strong>Create a New Ticket:</strong> For detailed inquiries or issues, you can create a new support ticket directly through our platform. This helps us track and resolve your concern efficiently.
                    </li>
                    <li>
                        <strong>Email:</strong> Send your questions or concerns to <a href="mailto:new-ticket@inbound.evalarm.app">new-ticket@inbound.evalarm.app</a>. Each email automatically creates a new support ticket in our system.
                    </li>
                    <li>
                        <strong>Knowledge Base:</strong> Before reaching out, you might find quick answers in our extensive <Link to="/help/kb">FAQ and troubleshooting guides</Link>. It's a great resource for common questions and issues.
                    </li>
                </ol>
            </>
        ),
        category: ArticleCategory.Contact,
    },
];

// Export an instance of the KnowledgeBase
export const knowledgeBase = new KnowledgeBase(initialArticles);