import React from 'react';
import { Helmet } from 'react-helmet';
import {
    Bell,
    Zap,
    Clock,
    DollarSign,
    ChevronRight,
    Smartphone,
    Check,
    Download, Info
} from 'lucide-react';
import { Link } from 'react-router-dom';
import {Plan, setBillingCycle} from "../../store/plansSlice";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../store";
import EVAlarmLayout from "../layout/EVAlarmLayout";
import ProductDemo from "./Landing/ProductDemo";
import AppPreview from "./Landing/AppPreview";

interface FeatureProps {
    icon: React.ReactNode;
    title: string;
    description: string;
}

const Feature: React.FC<FeatureProps> = ({ icon, title, description }) => (
    <div className="group bg-white p-8 rounded-2xl shadow-lg transition-all duration-300 hover:shadow-xl hover:-translate-y-1">
        <div className="text-teal-600 mb-4 transform transition-transform duration-300 group-hover:scale-110">{icon}</div>
        <h3 className="text-2xl font-semibold text-gray-800 mb-3">{title}</h3>
        <p className="text-gray-600">{description}</p>
    </div>
);


const PlanCard: React.FC<{ plan: Plan }> = ({ plan }) => {
    const dispatch = useDispatch();
    const billingCycle = useSelector((state: RootState) => state.plans.billingCycle);
    const displayPrice = billingCycle === 'yearly' ? plan.yearly_price : plan.monthly_price;
    const priceLabel = billingCycle === 'yearly' ? '/year' : '/mo';
    const savings = ((plan.monthly_price * 12 - plan.yearly_price) / (plan.monthly_price * 12) * 100).toFixed(0);

    const handleBillingCycleChange = () => {
        dispatch(setBillingCycle(billingCycle === 'monthly' ? 'yearly' : 'monthly'));
    };

    return (
        <div className="bg-white p-8 rounded-xl shadow-lg transition-all duration-300 hover:shadow-xl hover:-translate-y-1">
            <h3 className="text-2xl font-semibold text-gray-800 mb-4">{plan.name}</h3>
            <p className="text-gray-600 mb-6">{plan.description}</p>
            <p className="text-3xl font-bold text-teal-600 mb-2">${displayPrice}{priceLabel}</p>
            {billingCycle === 'yearly' && (
                <p className="text-sm text-green-600 mb-6">Save {savings}% with annual billing</p>
            )}
            {billingCycle === 'monthly' && (
                <p className="text-sm text-gray-600 mb-6 cursor-pointer hover:text-teal-600" onClick={handleBillingCycleChange}>
                    Switch to yearly billing (Save {savings}%)
                </p>
            )}
            <ul className="text-left mb-8">
                {plan.features.slice(0, 3).map((feature, index) => (
                    <li key={index} className="flex items-center mb-2">
                        <Check className="text-teal-500 mr-2" size={18} />
                        <span>{feature}</span>
                    </li>
                ))}
            </ul>
            <p className="text-sm text-gray-600 mb-4">
                Up to {plan.max_vehicles} vehicle{plan.max_vehicles > 1 ? 's' : ''}
            </p>
            <Link
                to={`/signup`}
                className="bg-teal-600 text-white px-6 py-2 rounded-full font-medium hover:bg-teal-700 transition duration-300 inline-flex items-center"
            >
                Start Now {/* Changed from "Start Free Trial" */}
                <ChevronRight className="ml-2" size={18} />
            </Link>
        </div>
    );
};


const EVAlarmLanding = () => {
    const plans = useSelector((state: RootState) => state.plans.plans);

    return (
        <EVAlarmLayout>
            <Helmet>
                <title>EVAlarm: Never Pay Tesla Overstay Fees Again | Automatic EV Charging Reminders</title>
                <meta name="description"
                      content="EVAlarm helps Tesla owners avoid overstay fees with persistent, automatic reminders. Never worry about forgetting to move your car again!"/>
                <meta name="keywords"
                      content="Tesla, overstay fees, EV charging, automatic reminders, electric vehicle, charging station alerts"/>
                <script type="application/ld+json">
                    {`
                    {
                        "@context": "https://schema.org",
                        "@type": "MobileApplication",
                        "name": "EVAlarm",
                        "operatingSystem": "iOS",
                        "applicationCategory": "UtilitiesApplication",
                        "offers": {
                            "@type": "Offer",
                            "price": "4.99",
                            "priceCurrency": "USD"
                        },
                        "description": "EVAlarm helps Tesla owners avoid overstay fees at charging stations with automatic, persistent reminders."
                    }
                    `}
                </script>
            </Helmet>
            <section className="py-20 md:py-32 text-center">
                <h1 className="text-5xl md:text-6xl font-extrabold text-gray-900 mb-6 leading-tight">Never Worry
                    About <span className="text-teal-600">Overstaying</span> Again</h1>
                <p className="text-xl md:text-2xl text-gray-700 mb-12 max-w-3xl mx-auto">
                    EVAlarm: Your personal assistant for stress-free Tesla charging. Automatic reminders ensure you
                    never forget to move your car, even if you forget to set an alarm.
                </p>
                <div
                    className="flex flex-col sm:flex-row justify-center items-center space-y-4 sm:space-y-0 sm:space-x-4">
                    <a href="#features"
                       className="bg-teal-600 text-white px-8 py-4 rounded-full text-lg font-semibold hover:bg-teal-700 transition duration-300 inline-flex items-center shadow-lg">
                        See How We Help
                        <ChevronRight className="w-5 h-5 ml-2"/>
                    </a>
                    <Link to="/download"
                          className="bg-white text-teal-600 border-2 border-teal-600 px-8 py-4 rounded-full text-lg font-semibold hover:bg-teal-50 transition duration-300 inline-flex items-center shadow-lg">
                        Download Now
                        <Download className="w-5 h-5 ml-2"/>
                    </Link>
                </div>
            </section>

            {/* App Preview Section */}
            <section className="my-32 flex flex-col md:flex-row items-center justify-between">
                <div className="md:w-1/2 mb-12 md:mb-0 md:pr-12">
                    <h2 className="text-4xl font-bold mb-8 text-gray-900 leading-tight">Your Charging <span
                        className="text-teal-600">Guardian</span></h2>
                    <p className="text-xl text-gray-700 mb-10 leading-relaxed">
                        We understand life can be hectic. EVAlarm is designed for Tesla owners who occasionally lose
                        track of time or miss notifications. We're here to ensure you never face overstay fees
                        again.
                    </p>
                    <ul className="space-y-6">
                        {[
                            "Persistent reminders that won't let you forget",
                            "Customizable alerts based on your habits",
                            "Easy tracking of charging sessions and costs"
                        ].map((feature, index) => (
                            <li key={index}
                                className="flex items-center bg-white bg-opacity-50 p-4 rounded-lg shadow-sm">
                                <Check className="text-teal-600 mr-3 flex-shrink-0"/>
                                <span className="text-gray-800">{feature}</span>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="md:w-1/2 flex justify-center">
                    <AppPreview/> {/* Replace MobileMockup with AppPreview */}
                </div>
            </section>

            <section className="my-32">
                <h2 className="text-4xl font-bold text-center mb-12 text-gray-900 leading-tight">Experience <span
                    className="text-teal-600">EVAlarm</span> in Action</h2>
                <ProductDemo/>
            </section>

            <section id="features"
                     className="my-32 bg-gradient-to-br from-teal-500 to-cyan-600 text-white py-20 rounded-3xl">
                <h2 className="text-4xl font-bold text-center mb-16 leading-tight">How EVAlarm <span
                    className="text-teal-200">Saves Your Day</span></h2>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-12 px-6">
                    <Feature
                        icon={<Bell className="w-12 h-12"/>}
                        title="Persistent Reminders"
                        description="We'll keep reminding you until you're back at your car. No notification left behind!"
                    />
                    <Feature
                        icon={<Clock className="w-12 h-12"/>}
                        title="Time Management"
                        description="Set custom timers based on your usual routines. We adapt to your lifestyle."
                    />
                    <Feature
                        icon={<DollarSign className="w-12 h-12"/>}
                        title="Fee Prevention"
                        description="Say goodbye to unexpected overstay fees. We help you save money and stress."
                    />
                </div>
            </section>

            <section className="my-32 text-center">
                <h2 className="text-4xl font-bold mb-6 text-gray-900 leading-tight">Frequently Asked <span
                    className="text-teal-600">Questions</span></h2>
                <div className="space-y-12">
                    <div className="text-left">
                        <h3 className="text-2xl font-semibold mb-4">How does EVAlarm prevent Tesla overstay fees?</h3>
                        <p className="text-gray-700">EVAlarm uses advanced algorithms to predict when you need to move
                            your Tesla, sending persistent reminders until you acknowledge them. This ensures you never
                            accidentally overstay at a charging station.</p>
                    </div>
                    <div className="text-left">
                        <h3 className="text-2xl font-semibold mb-4">Do I need to set alarms manually?</h3>
                        <p className="text-gray-700">No! EVAlarm automatically sets alarms based on your charging
                            session, so you're protected even if you forget to set a reminder yourself.</p>
                    </div>
                    <div className="text-left">
                        <h3 className="text-2xl font-semibold mb-4">Is EVAlarm compatible with all Tesla models?</h3>
                        <p className="text-gray-700">Yes, EVAlarm works seamlessly with all Tesla models, including
                            Model S, Model 3, Model X, and Model Y.</p>
                    </div>
                    <div className="text-center mt-16">
                        <p className="text-xl mb-6 text-gray-700">Can't find what you're looking for?</p>
                        <Link to="/help"
                              className="text-teal-600 hover:text-teal-700 font-semibold text-lg flex items-center justify-center">
                            Visit our Help Center
                            <ChevronRight className="ml-2" size={20}/>
                        </Link>
                    </div>
                </div>
            </section>

            <section className="my-32 text-center relative overflow-hidden">
                <div
                    className="absolute inset-0 bg-gradient-to-br from-teal-400 to-cyan-500 transform -skew-y-6 z-0"></div>
                <div className="relative z-10 py-20 px-6">
                    <h2 className="text-4xl font-bold mb-6 text-white leading-tight">Stop Worrying, <span
                        className="text-teal-200">Start Charging</span></h2>
                    <p className="text-2xl text-white mb-6">Join the Forgetful-Friendly EV Revolution</p>
                    <p className="text-lg text-teal-100 mb-12 max-w-2xl mx-auto">
                        Don't let a busy life cost you money. With EVAlarm, you can focus on what matters while we
                        take care of your charging schedule.
                    </p>
                    <a href="#"
                       className="bg-white text-teal-600 px-10 py-4 rounded-full text-xl font-semibold hover:bg-teal-100 transition duration-300 inline-flex items-center shadow-lg">
                        Start Your Worry-Free Charging Today
                        <ChevronRight className="w-6 h-6 ml-2"/>
                    </a>
                </div>
            </section>

            {/* PRICING section */}
            <section id="pricing" className="my-32 text-center">
                <h2 className="text-4xl font-bold mb-6 text-gray-900 leading-tight">Choose Your <span
                    className="text-teal-600">Plan</span></h2>
                <p className="text-xl text-gray-700 mb-4 max-w-2xl mx-auto">
                    Select the perfect plan for your EV charging needs. All plans come with a 60-day money-back
                    guarantee!*
                </p>
                <p className="text-sm text-gray-500 mb-16 max-w-2xl mx-auto">
                    *60-day money-back guarantee applies only to plans purchased through our web app.
                    App store purchases are subject to the respective store's refund policy.
                </p>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
                    {plans.map((plan) => (
                        <PlanCard key={plan.id} plan={plan}/>
                    ))}
                </div>
                <div className="mt-16">
                    <Link to="/plans"
                          className="text-teal-600 font-semibold text-lg hover:text-teal-700 transition duration-300">
                        Compare all plans
                        <ChevronRight className="inline ml-2" size={20}/>
                    </Link>
                </div>
                <div className="mt-8 text-center">
                    <p className="text-sm text-gray-500 flex items-center justify-center">
                        <Info className="h-4 w-4 mr-2"/>
                        60-day money-back guarantee applies only to plans purchased through our web app.
                        App store purchases are subject to the respective store's refund policy.
                    </p>
                </div>
            </section>
        </EVAlarmLayout>
    );
};

export default EVAlarmLanding;