import React from 'react';
import { Link } from 'react-router-dom';
import { HelpCircle, LogIn, Download } from 'lucide-react';
import LogoIcon from "../common/LogoIcon";

interface EVAlarmLayoutProps {
    children: React.ReactNode;
}

const EVAlarmLayout: React.FC<EVAlarmLayoutProps> = ({ children }) => {
    return (
        <div className="min-h-screen bg-gradient-to-br from-teal-50 via-cyan-100 to-blue-200 font-sans text-gray-800">
            <header className="bg-white bg-opacity-90 backdrop-filter backdrop-blur-lg shadow-sm sticky top-0 z-50">
                <div className="container mx-auto px-6 py-4 flex justify-between items-center">
                    <div className="flex items-center">
                        <Link to="/" className="flex items-center space-x-2">
                            <LogoIcon size={32} />
                            <h1 className="text-2xl font-extrabold text-teal-600 tracking-tight">EVAlarm</h1>
                        </Link>
                        <span className="text-sm text-gray-600 italic ml-4 hidden sm:inline-block">Say Goodbye to Overstays</span>
                    </div>
                    <nav className="hidden md:flex items-center space-x-8">
                        <Link to="/download" className="text-gray-600 hover:text-teal-600 transition duration-300 flex items-center">
                            <Download className="w-4 h-4 mr-1" />
                            Download
                        </Link>
                        <Link to="/help" className="text-gray-600 hover:text-teal-600 transition duration-300 flex items-center">
                            <HelpCircle className="w-4 h-4 mr-1" />
                            Help
                        </Link>
                        <Link to="/signin" className="bg-teal-600 text-white px-4 py-2 rounded-full text-sm font-medium hover:bg-teal-700 transition duration-300 flex items-center">
                            <LogIn className="w-4 h-4 mr-1" />
                            Sign In
                        </Link>
                    </nav>
                </div>
            </header>

            <main className="container mx-auto px-4 sm:px-6 lg:px-8">
                {children}
            </main>

            <footer className="bg-gray-900 text-white py-16">
                <div className="container mx-auto px-6 text-center">
                    <p className="text-2xl font-semibold mb-4">EVAlarm: Say Goodbye to Overstays</p>
                    <p className="text-lg mb-6">&copy; 2024 EVAlarm. Supporting Tesla owners in efficient charging.</p>
                    <p className="text-base text-gray-400 mb-10">Designed for all Tesla models. More EV brands coming soon!</p>
                    <div className="flex flex-wrap justify-center space-x-8 space-y-4 md:space-y-0">
                        <a href="/privacy" className="text-gray-300 hover:text-white transition duration-300">Privacy Policy</a>
                        <a href="/tos" className="text-gray-300 hover:text-white transition duration-300">Terms of Service</a>
                        <Link to="/help" className="text-gray-300 hover:text-white transition duration-300">Help Center</Link>
                        <Link to="/help/ticket" className="text-gray-300 hover:text-white transition duration-300">Contact Us</Link>
                        <Link to="/download" className="text-gray-300 hover:text-white transition duration-300">Download</Link>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default EVAlarmLayout;