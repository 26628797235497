import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import {
    createRegistrationSessionRequest,
    updateRegistrationSessionRequest,
    getRegistrationSessionRequest,
    updateLocalRegistrationSession,
    setLocalStep, resetRegistrationState, checkRegistrationStatusRequest
} from '../../store/registrationSlice';
import AuthLayout from "../layout/AuthLayout";
import { EmailPasswordStep } from "./SignupForm/EmailPasswordStep";
import { PlanSelectionStep } from "./SignupForm/PlanSelectionStep";
import LoadingScreen from "./LoadingScreen";
import StripeWrapper from "../common/StripeWrapper";
import { addWeeks } from "date-fns";
import { PaymentStepWrapper } from "./SignupForm/PaymentStepWrapper";
import { useNavigate } from 'react-router-dom';

const SignUpForm: React.FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {
        sessionId,
        step,
        localStep,
        email,
        planId,
        billingFrequency,
        clientSecret,
        paymentIntentId,
        totalAmount,
        currency,
        updatedAt,
        loading,
        error
    } = useSelector((state: RootState) => state.registration);
    const isAuthenticated = useSelector((state: RootState) => state.user.isAuthenticated);

    const [password, setPassword] = useState('');
    const [initialEmail, setInitialEmail] = useState(email || '');

    useEffect(() => {
        const checkAndUpdateSession = () => {
            if (!sessionId) {
                dispatch(createRegistrationSessionRequest());
            } else if (updatedAt) {
                const lastUpdateDate = new Date(updatedAt * 1000);
                const oneWeekAgo = addWeeks(new Date(), -1);

                if (lastUpdateDate < oneWeekAgo) {
                    dispatch(createRegistrationSessionRequest());
                } else {
                    dispatch(getRegistrationSessionRequest(sessionId));
                }
            } else {
                dispatch(getRegistrationSessionRequest(sessionId));
            }
        };

        checkAndUpdateSession();
    }, [dispatch, sessionId, updatedAt]);

    useEffect(() => {
        if (email && !initialEmail) {
            setInitialEmail(email);
        }
    }, [email, initialEmail]);

    useEffect(() => {
        if (step === 'completed' || localStep === 'completed') {
            console.log('Registration completed, checking status');
            if (sessionId) {
                dispatch(checkRegistrationStatusRequest(sessionId));
            }
        }
    }, [step, localStep, sessionId, dispatch]);

    useEffect(() => {
        if (isAuthenticated) {
            dispatch(resetRegistrationState());
            navigate('/dashboard');
        }
    }, [isAuthenticated, navigate, dispatch]);

    const handleEmailChange = useCallback((newEmail: string) => {
        dispatch(updateLocalRegistrationSession({ email: newEmail }));
    }, [dispatch]);

    const handleEmailPasswordSubmit = (submittedEmail: string, submittedPassword: string) => {
        setPassword(submittedPassword);
        if (sessionId) {
            const updatePayload: any = {
                sessionId,
                email: submittedEmail,
                step: 'personal_info'
            };
            if (submittedPassword) {
                updatePayload.password = submittedPassword;
            }
            dispatch(updateRegistrationSessionRequest(updatePayload));
        }
    };

    const handlePlanSelection = (selectedPlanId: string, selectedBillingFrequency: 'monthly' | 'yearly') => {
        if (sessionId) {
            dispatch(updateRegistrationSessionRequest({
                sessionId,
                planId: selectedPlanId,
                billingFrequency: selectedBillingFrequency,
                step: 'plan_selection'
            }));
        }
    };

    const handlePaymentSuccess = () => {
        dispatch(setLocalStep('confirmation'));
    };

    const handlePaymentError = (error: string) => {
        dispatch(setLocalStep('payment'));
        alert(`Payment failed: ${error}. Please try again.`);
    };

    const getStepTitle = () => {
        switch (localStep) {
            case 'personal_info': return "Create your account";
            case 'plan_selection': return "Choose your plan";
            case 'payment': return "Payment details";
            case 'confirmation': return "Payment confirmed";
            case 'completed': return "Registration completed";
            default: return "Sign Up";
        }
    };

    const renderStep = () => {
        switch (localStep) {
            case 'initiated':
            case 'personal_info':
                return (
                    <EmailPasswordStep
                        email={email || ''}
                        setEmail={handleEmailChange}
                        password={password}
                        setPassword={setPassword}
                        onNext={handleEmailPasswordSubmit}
                        initialEmail={initialEmail}
                        remoteStep={step}
                    />
                );
            case 'plan_selection':
                return (
                    <PlanSelectionStep
                        selectedPlanId={planId || ''}
                        setSelectedPlanId={(newPlanId) => handlePlanSelection(newPlanId, billingFrequency || 'monthly')}
                        selectedBillingFrequency={billingFrequency || 'monthly'}
                        setSelectedBillingFrequency={(newFrequency) => planId && handlePlanSelection(planId, newFrequency)}
                        onNext={() => dispatch(setLocalStep('payment'))}
                        onBack={() => dispatch(setLocalStep('personal_info'))}
                    />
                );
            case 'payment':
                return clientSecret && paymentIntentId && currency && totalAmount && sessionId ? (
                    <StripeWrapper>
                        <PaymentStepWrapper
                            sessionId={sessionId}
                            onPaymentSuccess={handlePaymentSuccess}
                            onPaymentError={handlePaymentError}
                            onBack={() => dispatch(setLocalStep('plan_selection'))}
                        />
                    </StripeWrapper>
                ) : null;
            case 'confirmation':
                return (
                    <div>
                        <p>Payment confirmed! Completing your registration...</p>
                        <LoadingScreen />
                    </div>
                );
            case 'completed':
                return (
                    <div>
                        <p>Registration completed! Redirecting to dashboard... If nothing happens, feel free to refresh the page</p>
                        <LoadingScreen />
                    </div>
                );
            default:
                return null;
        }
    };

    if (loading && localStep !== 'payment' && localStep !== 'confirmation' && localStep !== 'completed') {
        return <LoadingScreen />;
    }

    return (

        <AuthLayout
            title={getStepTitle()}
            subtitle="Start your EVAlarm experience"
        >
            {renderStep()}
            {error && <p className="text-red-500 mt-4">{error}</p>}
        </AuthLayout>
    );
};

export default SignUpForm;