import React from 'react';
import EVAlarmLayout from '../layout/EVAlarmLayout';

const ClientDownload: React.FC = () => {
    const isRegistrationEnabled = import.meta.env.VITE_REGISTRATION_ENABLED === 'true';
    const downloadLink = isRegistrationEnabled
        ? "https://apps.apple.com/app/id6670730568"
        : "/presign-up";

    return (
        <EVAlarmLayout>
            <div className="py-20 md:py-32">
                <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
                    <h1 className="text-5xl font-extrabold text-gray-900 text-center mb-8">Download EVAlarm</h1>
                    <p className="text-xl text-gray-700 text-center mb-12">
                        Get the EVAlarm app on your device and never worry about overstaying your charging time again!
                    </p>

                    <div className="grid md:grid-cols-3 gap-8">
                        {/* iOS Download */}
                        <div className="bg-white rounded-2xl shadow-lg p-8 text-center">
                            <svg viewBox="0 0 384 512" className="w-20 h-20 mx-auto mb-6 text-gray-800">
                                <path fill="currentColor" d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"/>
                            </svg>
                            <h2 className="text-2xl font-bold mb-4">iOS App</h2>
                            <p className="mb-6">Available for iPhone and iPad</p>
                            <a
                                href={downloadLink}
                                className="inline-block bg-teal-600 text-white px-6 py-3 rounded-full font-semibold hover:bg-teal-700 transition duration-300"
                            >
                                {isRegistrationEnabled ? "Download on the App Store" : "Join Waitlist"}
                            </a>
                        </div>

                        {/* Android Download (Coming Soon) */}
                        <div className="bg-white rounded-2xl shadow-lg p-8 text-center">
                            <svg viewBox="0 0 576 512" className="w-20 h-20 mx-auto mb-6 text-green-500">
                                <path fill="currentColor" d="M420.55,301.93a24,24,0,1,1,24-24,24,24,0,0,1-24,24m-265.1,0a24,24,0,1,1,24-24,24,24,0,0,1-24,24m273.7-144.48,47.94-83a10,10,0,1,0-17.27-10h0l-48.54,84.07a301.25,301.25,0,0,0-246.56,0L116.18,64.45a10,10,0,1,0-17.27,10h0l47.94,83C64.53,202.22,8.24,285.55,0,384H576c-8.24-98.45-64.54-181.78-146.85-226.55"/>
                            </svg>
                            <h2 className="text-2xl font-bold mb-4">Android App</h2>
                            <p className="mb-6">Coming soon for Android devices</p>
                            <button
                                className="bg-gray-300 text-gray-600 px-6 py-3 rounded-full font-semibold cursor-not-allowed"
                                disabled
                            >
                                Coming Soon
                            </button>
                        </div>

                        {/* Discord Integration */}
                        <div className="bg-white rounded-2xl shadow-lg p-8 text-center">
                            <svg viewBox="0 0 640 512" className="w-20 h-20 mx-auto mb-6 text-indigo-600">
                                <path fill="currentColor" d="M524.531,69.836a1.5,1.5,0,0,0-.764-.7A485.065,485.065,0,0,0,404.081,32.03a1.816,1.816,0,0,0-1.923.91,337.461,337.461,0,0,0-14.9,30.6,447.848,447.848,0,0,0-134.426,0,309.541,309.541,0,0,0-15.135-30.6,1.89,1.89,0,0,0-1.924-.91A483.689,483.689,0,0,0,116.085,69.137a1.712,1.712,0,0,0-.788.676C39.068,183.651,18.186,294.69,28.43,404.354a2.016,2.016,0,0,0,.765,1.375A487.666,487.666,0,0,0,176.02,479.918a1.9,1.9,0,0,0,2.063-.676A348.2,348.2,0,0,0,208.12,430.4a1.86,1.86,0,0,0-1.019-2.588,321.173,321.173,0,0,1-45.868-21.853,1.885,1.885,0,0,1-.185-3.126c3.082-2.309,6.166-4.711,9.109-7.137a1.819,1.819,0,0,1,1.9-.256c96.229,43.917,200.41,43.917,295.5,0a1.812,1.812,0,0,1,1.924.233c2.944,2.426,6.027,4.851,9.132,7.16a1.884,1.884,0,0,1-.162,3.126,301.407,301.407,0,0,1-45.89,21.83,1.875,1.875,0,0,0-1,2.611,391.055,391.055,0,0,0,30.014,48.815,1.864,1.864,0,0,0,2.063.7A486.048,486.048,0,0,0,610.7,405.729a1.882,1.882,0,0,0,.765-1.352C623.729,277.594,590.933,167.465,524.531,69.836ZM222.491,337.58c-28.972,0-52.844-26.587-52.844-59.239S193.056,219.1,222.491,219.1c29.665,0,53.306,26.82,52.843,59.239C275.334,310.993,251.924,337.58,222.491,337.58Zm195.38,0c-28.971,0-52.843-26.587-52.843-59.239S388.437,219.1,417.871,219.1c29.667,0,53.307,26.82,52.844,59.239C470.715,310.993,447.538,337.58,417.871,337.58Z"/>
                            </svg>
                            <h2 className="text-2xl font-bold mb-4">Discord Bot</h2>
                            <p className="mb-6">Integration coming soon!</p>
                            <button
                                className="bg-indigo-600 text-white px-6 py-3 rounded-full font-semibold hover:bg-indigo-700 transition duration-300 cursor-not-allowed"
                                disabled
                            >
                                Stay Tuned
                            </button>
                        </div>
                    </div>

                    <div className="mt-16 text-center">
                        <h3 className="text-2xl font-bold mb-4">Why Use EVAlarm?</h3>
                        <ul className="text-left max-w-md mx-auto">
                            <li className="flex items-start mb-4">
                                <svg className="w-6 h-6 text-teal-500 mr-2 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path></svg>
                                <span>Get timely reminders to move your car</span>
                            </li>
                            <li className="flex items-start mb-4">
                                <svg className="w-6 h-6 text-teal-500 mr-2 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path></svg>
                                <span>Track your charging sessions and costs</span>
                            </li>
                            <li className="flex items-start mb-4">
                                <svg className="w-6 h-6 text-teal-500 mr-2 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path></svg>
                                <span>Customize alerts based on your preferences</span>
                            </li>
                            <li className="flex items-start mb-4">
                                <svg className="w-6 h-6 text-teal-500 mr-2 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path></svg>
                                <span>Discord integration for seamless notifications</span>
                            </li>
                            <li className="flex items-start mb-4">
                                <svg className="w-6 h-6 text-teal-500 mr-2 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path></svg>
                                <span>SMS integration on the horizon</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </EVAlarmLayout>
    );
};

export default ClientDownload;