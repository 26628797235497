import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum SubscriptionStatus {
    Trialing = 'trialing',
    Active = 'active',
    PastDue = 'past_due',
    Canceled = 'canceled',
    Invalid = 'is_invalid',
}

export enum CancellationStatus {
    Idle = 'idle',
    Pending = 'pending',
    Success = 'success',
    Error = 'error'
}

export enum BillingFrequency {
    Monthly = 'monthly',
    Yearly = 'yearly'
}

export enum PaymentProcessor {
    Stripe = 'stripe',
    AppleIAP = 'apple_iap'
}

export interface PriceEstimate {
    immediate_charge: number;
    proration_breakdown: {
        prorated_new_plan_cost: number;
        unused_current_plan_credit: number;
    } | null;
    next_billing_date: string;
    next_bill_amount: number;
    new_plan: string;
    billing_frequency: BillingFrequency;
}

export interface SubscriptionState {
    currentPlan: string;
    paymentMethod: string;
    subscriptionStatus: string;
    paymentProcessor: PaymentProcessor;
    loading: boolean;
    error: string | null;
    billingFrequency: string | null;
    hasSubscription: boolean;
    endsAt: number | null;
    cancelledAt: number | null;
    pendingPlan: string| null;
    pendingBillingFrequency: string| null;
    pendingCancellation: boolean;
    autoRenewal: boolean;
    canRefund: boolean;
    cancellationStatus: CancellationStatus;
    priceEstimate: PriceEstimate | null;
}

const initialState: SubscriptionState = {
    currentPlan: 'Loading...',
    paymentMethod: 'Loading...',
    subscriptionStatus: 'Loading...',
    paymentProcessor: PaymentProcessor.Stripe,
    billingFrequency: null,
    loading: false,
    error: null,
    hasSubscription: true,
    endsAt: null,
    cancelledAt: null,
    pendingPlan: null,
    pendingBillingFrequency: null,
    pendingCancellation: false,
    autoRenewal: true,
    canRefund: false,
    cancellationStatus: CancellationStatus.Idle,
    priceEstimate: null,
};


export interface SubscriptionPayload {
    current_plan: string;
    payment_method: string;
    subscription_status: SubscriptionStatus;
    billing_frequency: BillingFrequency;
    cancelled_at: number | null;
    ends_at: number | null
    pending_plan: string | null;
    pending_billing_frequency: BillingFrequency | null;
    payment_processor: PaymentProcessor;
    money_back_guarantee_ends_at: number;
    can_refund: boolean;
    auto_renewal: boolean;
}

const mapPayloadToState = (payload: SubscriptionPayload): Partial<SubscriptionState> => ({
    currentPlan: payload.current_plan,
    paymentMethod: payload.payment_method,
    subscriptionStatus: payload.subscription_status,
    billingFrequency: payload.billing_frequency,
    cancelledAt: payload.cancelled_at,
    endsAt: payload.ends_at,
    pendingPlan: payload.pending_plan,
    pendingBillingFrequency: payload.pending_billing_frequency,
    paymentProcessor: payload.payment_processor,
    canRefund: payload.can_refund,
    autoRenewal: payload.auto_renewal
});

export interface CancelSubscriptionPayload {
    refund: boolean;
    feedback: string;
}

const subscriptionSlice = createSlice({
    name: 'subscription',
    initialState,
    reducers: {
        fetchSubscriptionStart(state) {
            state.loading = true;
            state.error = null;
        },
        fetchSubscriptionSuccess(state, action: PayloadAction<SubscriptionPayload>) {
            return {
                ...state,
                ...mapPayloadToState(action.payload),
                loading: false,
                error: null,
                hasSubscription: true
            };
        },
        fetchSubscriptionFailure(state, action: PayloadAction<string>) {
            state.loading = false;
            state.error = action.payload;
            if (action.payload === 'Subscription not found') {
                state.hasSubscription = false;
                state.currentPlan = 'No active subscription';
                state.paymentMethod = 'No payment method';
                state.subscriptionStatus = 'Inactive';
                state.pendingPlan = null;
                state.pendingBillingFrequency = null;
                state.billingFrequency = null;
                state.endsAt = null;
                state.cancelledAt = null;
            }
        },
        updatePlanStart(state, action: PayloadAction<{ planId: string, billingFrequency: string }>) {
            state.loading = true;
            state.error = null;
        },
        updatePlanSuccess(state, action: PayloadAction<SubscriptionPayload>) {
            return {
                ...state,
                ...mapPayloadToState(action.payload),
                loading: false,
                error: null,
                hasSubscription: true
            };
        },
        updatePlanFailure(state, action: PayloadAction<string>) {
            state.loading = false;
            state.error = action.payload;
        },
        updatePaymentMethodStart(state, action: PayloadAction<{ paymentMethodId: string }>) {
            state.loading = true;
            state.error = null;
        },
        updatePaymentMethodSuccess(state, action: PayloadAction<SubscriptionPayload>) {
            return {
                ...state,
                ...mapPayloadToState(action.payload),
                loading: false,
                error: null,
                hasSubscription: true
            };
        },
        updatePaymentMethodFailure(state, action: PayloadAction<string>) {
            state.loading = false;
            state.error = action.payload;
        },
        createSubscriptionStart(state, action: PayloadAction<{ planId: string, paymentMethodId: string, billingFrequency: string }>) {
            state.loading = true;
            state.error = null;
        },
        createSubscriptionSuccess(state, action: PayloadAction<SubscriptionPayload>) {
            return {
                ...state,
                ...mapPayloadToState(action.payload),
                loading: false,
                error: null,
                hasSubscription: true
            };
        },
        createSubscriptionFailure(state, action: PayloadAction<string>) {
            state.loading = false;
            state.error = action.payload;
        },
        estimatePriceStart(state, action: PayloadAction<{ planId: string, billingFrequency: string }>) {
            state.loading = true;
            state.error = null;
        },
        estimatePriceSuccess(state, action: PayloadAction<PriceEstimate>) {
            state.priceEstimate = action.payload;
            state.loading = false;
            state.error = null;
        },
        estimatePriceFailure(state, action: PayloadAction<string>) {
            state.loading = false;
            state.error = action.payload;
        },
        resetCancellationStatus(state) {
            state.cancellationStatus = CancellationStatus.Idle;
        },
        cancelSubscriptionStart(state, action: PayloadAction<CancelSubscriptionPayload>) {
            state.loading = true;
            state.error = null;
            state.cancellationStatus = CancellationStatus.Pending;
        },
        cancelSubscriptionSuccess(state, action: PayloadAction<SubscriptionPayload>) {
            return {
                ...state,
                ...mapPayloadToState(action.payload),
                loading: false,
                error: null,
                cancellationStatus: CancellationStatus.Success,
            };
        },
        cancelSubscriptionFailure(state, action: PayloadAction<string>) {
            state.loading = false;
            state.error = action.payload;
            state.cancellationStatus = CancellationStatus.Error;
        },
        reactivateSubscriptionStart(state) {
            state.loading = true;
            state.error = null;
        },
        reactivateSubscriptionSuccess(state, action: PayloadAction<SubscriptionPayload>) {
            return {
                ...state,
                ...mapPayloadToState(action.payload),
                loading: false,
                error: null,
                hasSubscription: true
            };
        },
        reactivateSubscriptionFailure(state, action: PayloadAction<string>) {
            state.loading = false;
            state.error = action.payload;
        },
    },
});

export const {
    fetchSubscriptionStart,
    fetchSubscriptionSuccess,
    fetchSubscriptionFailure,
    updatePlanStart,
    updatePlanSuccess,
    updatePlanFailure,
    updatePaymentMethodStart,
    updatePaymentMethodSuccess,
    updatePaymentMethodFailure,
    createSubscriptionStart,
    createSubscriptionSuccess,
    createSubscriptionFailure,
    estimatePriceStart,
    estimatePriceSuccess,
    estimatePriceFailure,
    cancelSubscriptionStart,
    cancelSubscriptionSuccess,
    cancelSubscriptionFailure,
    reactivateSubscriptionStart,
    reactivateSubscriptionSuccess,
    reactivateSubscriptionFailure,
    resetCancellationStatus,
} = subscriptionSlice.actions;

export default subscriptionSlice.reducer;